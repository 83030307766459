const wrapper = <HTMLDivElement>document.getElementById("wrapper")!;

const loginUsernameField = <HTMLInputElement>document.getElementById("login-username")!;
const signupUsernameField = <HTMLInputElement>document.getElementById("signup-username")!;

(<HTMLButtonElement>document.getElementById("goto-signup-btn")!).onclick = () => {
	wrapper.style.marginTop = "-100vh";
	loginUsernameField.blur();
	setTimeout(() => {
		signupUsernameField.select();
	}, 250);
};

(<HTMLButtonElement>document.getElementById("goto-login-btn")!).onclick = () => {
	wrapper.style.marginTop = "0vh";
	signupUsernameField.blur();
	setTimeout(() => {
		loginUsernameField.select();
	}, 250);
};

const usernameNotFoundError = <HTMLLabelElement>document.getElementById("username-not-found-error")!;
const invalidPasswordError = <HTMLLabelElement>document.getElementById("invalid-password-error")!;
const loginError = <HTMLLabelElement>document.getElementById("login-error")!;

const loginForm = <HTMLFormElement>document.getElementById("login-form")!;
const signupForm = <HTMLFormElement>document.getElementById("signup-form")!;

const loginHandler = async () => {
	const formData = new FormData(loginForm).entries();
	const formDataObj = Object.fromEntries(formData);

	const res = await fetch("https://bois.work/auth/login", {
		method: "POST",
		mode: "cors",
		cache: "no-cache",
		credentials: "include",
		headers: {
			"Content-Type": "application/json",
		},
		redirect: "follow",
		referrerPolicy: "no-referrer",
		body: JSON.stringify(formDataObj),
	});

	switch (res.status) {
		case 200:
			window.location.replace("https://bois.work/");
			break;

		case 500:
			loginError.style.display = "inline";
			break;

		case 404:
			usernameNotFoundError.style.display = "inline";
			break;

		case 401:
			invalidPasswordError.style.display = "inline";
			break;

		default:
			loginError.style.display = "inline";
			break;
	}
};

const passwordMatchError = <HTMLLabelElement>document.getElementById("password-match-error")!;
const usernameTakenError = <HTMLLabelElement>document.getElementById("username-taken-error")!;
const registrationKeyError = <HTMLLabelElement>document.getElementById("registration-key-error")!;
const signupError = <HTMLLabelElement>document.getElementById("signup-error")!;

const signupHandler = async () => {
	const formData = new FormData(signupForm).entries();
	const formDataObj = Object.fromEntries(formData);

	if (formDataObj.password !== formDataObj.confirmPassword) {
		passwordMatchError.style.display = "inline";
		return;
	}

	const res = await fetch("https://bois.work/auth/signup", {
		method: "POST",
		mode: "cors",
		cache: "no-cache",
		credentials: "include",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
		},
		redirect: "follow",
		referrerPolicy: "no-referrer",
		body: JSON.stringify(formDataObj),
	});

	switch (res.status) {
		case 201:
			window.location.replace("https://bois.work/");
			break;

		case 500:
			signupError.style.display = "inline";
			break;

		case 409:
			usernameTakenError.style.display = "inline";
			break;

		case 403:
			registrationKeyError.style.display = "inline";
			break;

		default:
			signupError.style.display = "inline";
			break;
	}
};

const loginSubmitInput = <HTMLInputElement>document.querySelector("#login-form input[type='password']")!;
const signupSubmitInput = <HTMLInputElement>document.querySelector("#signup-form input[type='password']")!;

(<HTMLButtonElement>document.getElementById("login-btn")).addEventListener("click", loginHandler);
loginSubmitInput.addEventListener("keydown", (e) => {
	if (e.key === "Enter") {
		loginHandler();
	}
});

(<HTMLButtonElement>document.getElementById("signup-btn")).addEventListener("click", signupHandler);
signupSubmitInput.addEventListener("keydown", (e) => {
	if (e.key === "Enter") {
		signupHandler();
	}
});

window.onkeydown = (event: KeyboardEvent) => {
	if ((<HTMLElement>event.target!).nodeName === "INPUT") {
		usernameNotFoundError.style.display = "none";
		invalidPasswordError.style.display = "none";

		passwordMatchError.style.display = "none";
		usernameTakenError.style.display = "none";
		registrationKeyError.style.display = "none";
	}
};

window.onload = () => {
	loginUsernameField.select();
};
